<template>
    <div class="carbonManage-page">
        <!-- 头部 -->
        <div class="carbonManage-header" :style="`background-image: url(${headerBackgroundImg})`">
            <div class="carbonManage-header_text">
                <p>企业碳管理</p>
                <p>Enterprise carbon management</p>
            </div>
        </div>
        <!-- 企业正面临着碳管理的挑战 -->
        <div class="carbonManage-challenge">
            <div class="carbonManage-title">
                <img :src="decorationIcon" />
                <label>企业正面临着碳管理的挑战</label>
            </div>
            <div class="carbonManage-tabs">
                <div :class="['carbonManage-tabs_item', item.key == tabKey ? 'active-color' : '']" v-for="(item, index) in tabsList" :key="index" @click="tabClick(item.key, item)">
                    <div class="tabs_item-title">{{ item.title }}</div>
                </div>
            </div>
            <div class="carbonManage-pannel">
                <div class="carbonManage-pannel_img">
                    <img :src="tabsInfo.img" />
                </div>
                <div class="carbonManage-pannel_list">
                  <div class="pannel_list-item" v-for="(item, index) in tabsInfo.list" :key="index" :style="`background-image: url(${item.icon})`">
                       <div class="list-item_title">
                          <label>0{{ index + 1 }}</label>
                          <span>{{ item.title }}</span>
                       </div>
                       <div class="list-item_content">{{ item.content }}</div>
                  </div>
                </div>
            </div>
        </div>

        <!-- 应用场景 -->
        <div class="carbonManage-scene">
            <div class="carbonManage-title">
                <img :src="decorationIcon" />
                <label>应用场景</label>
            </div>
            <div class="carbonManage-collapse">
               <div class="carbonManage-collapse_item" v-for="(item, index) in sceneList" :key="index">
                  <div :class="['collapse_item-header', item.collapsed ? 'header-padding' : 'header-noPadding']" @click="collapse(index, item, 'sceneList')">
                     <img class="collapse-icon" :src="item.icon" />
                     <label>{{ item.title }}</label>
                     <img :class="['arrow-icon', item.collapsed ? 'img-collapseUp' : 'img-collapseDown']" :src="arrowIcon" />
                  </div>
                  <div class="collapse_item-content" v-if="item.collapsed">{{ item.content }}</div>
               </div>
            </div>
        </div>

        <!-- 核心价值 -->
        <div class="carbonManage-value">
            <div class="carbonManage-title">
                <img :src="decorationIcon" />
                <label>核心价值</label>
            </div>
            <div class="carbonManage-cards">
               <div class="carbonManage-cards_item" v-for="(item, index) in cardsList" :key="index">
                   <div class="cards_item-img">
                      <img :src="item.icon" />
                   </div>
                   <div class="cards_item-text">{{ item.content }}</div>
               </div>
            </div>
        </div>

        <!-- 联系我们 -->
        <footer-nav />
    </div>
</template>
<script>
import group1Img from '@/assets/images/carbonManage/mobile/group1Img.png';
import group2Img from '@/assets/images/carbonManage/mobile/group2Img.png';
import cardImg from '@/assets/images/carbonManage/mobile/cardImg.png';
import card1Img from '@/assets/images/carbonManage/mobile/card1Img.png';
import card2Img from '@/assets/images/carbonManage/mobile/card2Img.png';
import card3Img from '@/assets/images/carbonManage/mobile/card3Img.png';
import sceneIcon from '@/assets/images/carbonManage/mobile/sceneIcon.png';
import scene1Icon from '@/assets/images/carbonManage/mobile/scene1Icon.png';
import scene2Icon from '@/assets/images/carbonManage/mobile/scene2Icon.png';
import scene3Icon from '@/assets/images/carbonManage/mobile/scene3Icon.png';
import scene4Icon from '@/assets/images/carbonManage/mobile/scene4Icon.png';
import scene6Icon from '@/assets/images/carbonManage/mobile/scene6Icon.png';
import scene7Icon from '@/assets/images/carbonManage/mobile/scene7Icon.png';
import unionImg from '@/assets/images/carbonManage/mobile/unionImg.png';
import union1Img from '@/assets/images/carbonManage/mobile/union1Img.png';
import union2Img from '@/assets/images/carbonManage/mobile/union2Img.png';
import union3Img from '@/assets/images/carbonManage/mobile/union3Img.png';
import union4Img from '@/assets/images/carbonManage/mobile/union4Img.png';
import headerBackgroundImg from '@/assets/images/carbonManage/mobile/headerBackgroundImg.png';
import decorationIcon from '@/assets/images/carbonManage/mobile/decorationIcon.png';
import arrowIcon from '@/assets/images/carbonManage/mobile/arrowIcon.png';
export default {
    name: 'mobileCarbonManage',
    data() {
        return {
            arrowIcon,
            decorationIcon,
            headerBackgroundImg,
            group2Img,
            group1Img,
            tabKey: 'outer',
            tabsInfo: {},
            tabsList: [
                {
                   key: 'outer',
                   title: '外部挑战',  
                   img: group1Img
                },
                {
                   key: 'inner',
                   title: '内部挑战',
                   img: group2Img
                }
            ],
            unionOuterList: [
                {
                    title: '能耗双控转碳排放双控提出新要求',
                    icon: union4Img,
                    content: '国家发改委提出提出治理新规，逐步推动企业管控目标、产品标准体系以及统计计量核算体系从能耗双控到碳排放双控的转换。'
                },
                {
                    title: '欧盟系列新规提出新要求',
                    icon: union4Img,
                    content: '欧盟新电池法案、欧盟碳边境调节机制等系列新规均对外向型企业明确提出了产品碳足迹披露的要求。',
                },
                {
                    title: '绿色消费市场提出新要求',
                    icon: union4Img,
                    content: '消费市场受到政策执行、公众宣传及社会教育等影响，逐步形成以绿色消费为核心的市场理念。',
                },
                {
                    title: '零碳供应链提出新要求',
                    icon: union4Img,
                    content: '在制造业全产业链中，上游石化、冶金等重点排放企业处于碳市场管控范围，下游终端行业如汽车、电子产品等也纷纷提出零碳产业链目标。'
                }
            ],
            unionInnerList: [
                {
                    title: '技术成本',
                    icon: unionImg,
                    content: '碳管理工作需要满足企业专业人才储备、监测设备配备、数字化体系建设等人力物力技术基础建设。'
                },
                {
                    title: '管理成本',
                    icon: union1Img,
                    content: '碳管理与企业常规生产运营管理系统割裂，难以实现协同工作。',
                },
                {
                    title: '创新成本',
                    icon: union2Img,
                    content: '碳管理精细化程度及减排工作落实情况直接关乎企业是否能顺利实现可持续发展和碳中和目标。',
                },
                {
                    title: '生产运营成本',
                    icon: union3Img,
                    content: '碳管理工作需要满足企业专业人才储备、监测设备配备、数字化体系建设等人力物力技术基础建设。'
                },
            ],
            sceneList: [
                {
                    icon: sceneIcon,
                    title: '能碳数字空间构建',
                    content: '基于碳排放数据与物理世界关系梳理，应用数字孪生技术构建全景能碳数字空间，帮助企业全面且实时地掌握相关节点碳排放情况，为企业碳管理提供可视化数据底座。',
                    collapse: false
                },
                {
                    icon: scene1Icon,
                    title: '组织碳测算',
                    content: '基于国内外碳核算标准，科学测算企业碳排放，帮助企业掌握组织运营、产品生产等多层面的实际碳排放情况。',
                    collapse: false
                },
                {
                    icon: scene2Icon,
                    title: '碳足迹评价',
                    content: '基于国际碳足迹核算标准，合理测算特定产品不同生命周期碳足迹，帮助企业掌握各类产品在不同生命周期情况下的碳足迹环境影响结果信息。',
                    collapse: false
                },
                {
                    icon: scene3Icon,
                    title: '产品碳建模',
                    content: '基于国内外碳足迹边界标准及企业实际需求，提供多边界生命周期碳足迹建模选择，通过数据库和模型库辅助算法，实现拖拽式智能建模。',
                    collapse: false
                },
                {
                    icon: scene4Icon,
                    title: '碳数据分析',
                    content: '针对测算碳排放数据，通过横向纵向对比分析，识别相对高碳排环节，分析减排关键因素，帮助企业深入了解自身碳排放结构，科学制定碳排放目标。',
                    collapse: false
                },
                {
                    icon: scene6Icon,
                    title: '供应链碳管理',
                    content: '基于供应链碳减排需求，制定供应链碳管理绩效评估体系，通过为供应链碳管理及减排工作提供小程序等多路径服务，实现供应链协同减排。',
                    collapse: false
                },
                {
                    icon: scene7Icon,
                    title: '碳减排管理',
                    content: '围绕碳减排目标，结合碳数据分析情况，辅助企业制定科学减排路径，提供减排工作跨部门协同监管工具，保障工作成效。',
                    collapse: false
                }
            ],
            cardsList: [
                {
                    icon: cardImg,
                    content: '利用数字孪生技术打造全价值链能碳实景空间，挖掘企业碳数据资产价值',
                },
                {
                    icon: card1Img,
                    content: '形成科学应对内外部环境挑战的企业碳管理体系，保障企业零碳转型'
                },
                {
                    icon: card2Img,
                    content: '完成碳管理工作数字工具化，支持跨部门协同工作，落实企业节能减排规划部署'
                },
                {
                    icon: card3Img,
                    content: '实现供应链协同降碳，提升全价值链零碳竞争力，传递企业可持续发展理念'
                }
            ]
        }
    },
    mounted() {
        this.tabsList.forEach(item => {
             if(item.key == this.tabKey) this.tabsInfo = Object.assign({}, item, { list: this.tabKey == 'outer' ? this.unionOuterList : this.unionInnerList });
        })
    },
    methods: {
       collapse(index, item, key) {
          let handleItem = null;
          const funcItem = (collapsed) => {
             return {
                ...item,
                collapsed
             }
          };
          if(item.collapsed) {
             handleItem = funcItem(false);
          } else {
             handleItem = funcItem(true);
          }
          this[key] = this[key].map((element, eleIndex) =>
              eleIndex == index ? handleItem : element
          )
       },
       tabClick(key, info) {
         this.tabKey = key;
         this.tabsInfo = Object.assign({}, info, { list: key == 'outer' ? this.unionOuterList : this.unionInnerList });
       }
    }
}
</script>
<style lang="less" scoped>
@import url('./index.less');
</style>