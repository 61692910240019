<template>
  <component :is="componentTag" />
</template>
<script>
import webHome from "./web";
import mobileHome from "./mobile";
export default {
  components: {
    webHome,
    mobileHome,
  },
  data() {
    return {
      isMobile: false,
      componentTag: "",
    };
  },
  created() {
    this.listenObserverWidth(750);
  },
  methods: {
    // 监听可视窗口宽度
    listenObserverWidth(mobileWidth) {
      const resizeObserver = new ResizeObserver((entries) => {
        for (let entry of entries) {
          if (entry.contentRect.width <= mobileWidth) {
            this.componentTag = "webHome";
          } else {
            this.componentTag = "webHome";
          }
        }
      });
      resizeObserver.observe(document.body);
    },
  },
};
</script>