<template>
  <!-- 首页 -->
  <div class="page flex-col justify-end">
    <div class="home-header flex-col">
      <div class="home-header_banner flex-col" :style="`background-image: url(${bannerHeaderImg})`">
        <span class="header_banner-text1">欢迎来到中国产品数字护照网</span>
        <span class="header_banner-text2">专注中国产品数字护照的专业门户，中国产品数字护照体系服务的客户端统一入口。</span>
      </div>
      <div class="home-header_title flex-row justify-between">
        <img class="header_title-img" referrerpolicy="no-referrer" :src="decorationIcon" />
        <span class="header_title-label">产品数字护照</span>
      </div>
      <div class="home-header_box flex-row justify-between">
        <div class="header_box-section flex-col">
          <span class="box-section-title">什么是产品护照？</span>
          <div class="box-section-block1 flex-row justify-between">
            <img class="section-block1-img" referrerpolicy="no-referrer" :src="correctGreenIcon" />
            <span class="section-block1-label">产品数字护照是人类历史上首次构建的数据共享机制</span>
          </div>
          <div class="box-section-block2 flex-row justify-between">
            <img class="section-block2_img" referrerpolicy="no-referrer" :src="correctGreenIcon" />
            <span class="section-block2_text">具有全球性、跨行业、多主体,并且涵盖ESG数据等属性</span>
          </div>
          <div class="box-section-block3 flex-row justify-between">
            <img class="section-block3_img" referrerpolicy="no-referrer" :src="correctGreenIcon" />
            <span class="section-block3_label">为碳边境调节税机制调整奠定基础，为再全球化区域经济、贸易的纵深发展提供支撑</span>
          </div>
          <div class="box-section-block4 flex-row justify-between">
            <img class="section-block4_img" referrerpolicy="no-referrer" :src="correctGreenIcon" />
            <span class="section-block4_label">是数字化转型和绿色可持续发展转型的标志</span>
          </div>
          <div class="box-section-block5 flex-row justify-between">
            <img class="section-block5_img" referrerpolicy="no-referrer" :src="correctGreenIcon" />
            <span class="section-block5_label">是碳管理及企业“数绿双转”的重要技术手段</span>
          </div>
        </div>
        <img class="header_box-img" referrerpolicy="no-referrer" :src="backgroundImg" />
      </div>
    </div>
    <div class="home-middle flex-col justify-end">
      <div class="home-middle_title flex-row justify-between">
        <img class="middle_title-img" referrerpolicy="no-referrer" :src="decorationIcon" />
        <span class="middle_title-label">中国产品数字护照网</span>
      </div>
      <div class="home-middle_box flex-row">
        <div class="middle_box-group flex-col" :style="`background-image: url(${cardLeftDecorationImg})`">
          <span class="box-group_label">宗旨与愿景</span>
          <div class="box-group_wrapper flex-row justify-between">
            <img class="group_wrapper-img" referrerpolicy="no-referrer" :src="ellipseBlueGroupIcon" />
            <span class="group_wrapper-label">为更好的资源循环<br />为更低的能源碳耗<br />为促进循环经济<br />实现绿色可持续发展</span>
          </div>
          <img class="box-group_img" referrerpolicy="no-referrer" :src="cardLeftDecorationImg" />
        </div>
        <img class="middle_box-img" referrerpolicy="no-referrer" :src="cardTopImg" />
        <div class="middle_box-group1 flex-col"></div>
      </div>
      <div class="home-middle_box1 flex-row">
        <img class="middle_box1-img" referrerpolicy="no-referrer" :src="cardMiddleImg" />
        <div class="middle_box1-block flex-col" :style="`background-image: url(${cardRightDecorationImg})`">
          <span class="box1-block_label">目标与任务</span>
          <div class="box1-block_div flex-row justify-between">
            <img class="block_div-img" referrerpolicy="no-referrer" :src="ellipseGreenGroupIcon" />
            <span class="block_div-label">以行业项目为切入点<br />定义中国产品数字护照体系解决方案<br />塑造中国产品数字护照的未来</span>
          </div>
        </div>
        <div class="middle_box1-block1 flex-col">
          <img class="box1-block_img" referrerpolicy="no-referrer" :src="cardRightDecorationImg" />
        </div>
      </div>
      <div class="home-middle_box2 flex-row">
        <div class="middle_box2-block flex-col" :style="`background-image: url(${cardLeftDecorationImg})`">
          <span class="box2-block_label">工作与项目</span>
          <div class="box2-block_group flex-row justify-between">
            <div class="block_group-wrapper flex-col justify-between">
              <img class="group-wrapper_img1" referrerpolicy="no-referrer" :src="correctOrangeIcon" />
              <img class="group-wrapper_img2" referrerpolicy="no-referrer" :src="correctOrangeIcon" />
              <img class="group-wrapper_img3" referrerpolicy="no-referrer" :src="correctOrangeIcon" />
            </div>
            <span class="block_group-label">DPP相关动态与政策标准研究<br />进行产品数字护照的行业试点与部署<br />产品数字护照企业联合试点验证服务</span>
          </div>
          <img class="box2-block_img" referrerpolicy="no-referrer" :src="cardLeftDecorationImg" />
        </div>
        <img class="middle_box2-img" referrerpolicy="no-referrer" :src="cardBottomImg" />
        <div class="middle_box2-block1 flex-col"></div>
      </div>
    </div>
    <div class="home-footer flex-col">
      <div class="home-footer_title flex-row justify-between">
        <img class="footer_title-img" referrerpolicy="no-referrer" :src="decorationIcon" />
        <span class="footer_title-label">最新工作动态</span>
      </div>
      <div class="home-footer_group flex-row justify-between">
        <div class="home-footer_section flex-col" v-for="(item, index) in sectionList" :key="index">
          <div class="footer_section-div flex-col" :style="`background-image: url(${item.backgroundImg})`">
            <div class="section-div_wrapper flex-col">
              <span class="div_wrapper-time">{{ item.time }}</span>
            </div>
            <div class="section-div_style flex-col"></div>
          </div>
          <span class="footer_section-label">{{ item.title }}</span>
          <div class="footer_section-hover">
            <div class="hover-title">
              <span>{{ item.title }}</span>
            </div>
            <div class="hover-content">
              {{ item.content }}
            </div>
            <div class="hover-action">
              <div class="hover-action_btn" @click="convertUrl(item.url)">
                立即前往
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 表单 -->
      <form-nav v-if="$webType != 'cbia'" />
    </div>
    <!-- 尾部 -->
    <footer-nav />
  </div>
  <!-- 移动端首页 -->
  <!-- <div class="mobile-home" v-else></div> -->
</template>
<script>
import decorationIcon from '@/assets/images/home/decoration.png';
import ellipseBlueIcon from '@/assets/images/home/ellipse_blue.png';
import ellipseBlueGroupIcon from '@/assets/images/home/ellipse_blue_group.png';
import ellipseGreenGroupIcon from '@/assets/images/home/ellipse_green_group.png';
import cardTopImg from '@/assets/images/home/card_top.png';
import cardMiddleImg from '@/assets/images/home/card_middle.png';
import cardBottomImg from '@/assets/images/home/card_bottom.png';
import cardLeftDecorationImg from '@/assets/images/home/card_left_decoration.png';
import cardRightDecorationImg from '@/assets/images/home/card_right_decoration.png';
import correctOrangeIcon from '@/assets/images/home/corrent_orange.png';
import correctGreenIcon from '@/assets/images/home/correct_green.png';
import cardLeftImg from '@/assets/images/home/card_left.png';
import cardCenterImg from '@/assets/images/home/card_center.png';
import cardRightImg from '@/assets/images/home/card_right.png';
import bannerHeaderImg from '@/assets/images/home/banner_header.png';
import backgroundImg from '@/assets/images/home/background_img.png';
import sectionCover1 from '@/assets/images/home/sectionCover1.png';
import sectionCover2 from '@/assets/images/home/sectionCover2.png';
import sectionCover3 from '@/assets/images/home/sectionCover3.png';

export default {
  name: 'webHome',
  data() {
    return {
      isMobile: false,
      searchKeyWords: '产品数字护照是人类历史上首次构建的数据共享机制',
      decorationIcon,
      ellipseBlueIcon,
      ellipseBlueGroupIcon,
      ellipseGreenGroupIcon,
      cardTopImg,
      cardMiddleImg,
      cardBottomImg,
      cardLeftDecorationImg,
      cardRightDecorationImg,
      correctOrangeIcon,
      correctGreenIcon,
      bannerHeaderImg,
      backgroundImg,
      sectionList: [
        {
          time: '2024年09月04日',
          title: '复创大事记 | 人民网：一块锂电池的逐“绿”之旅',
          backgroundImg: sectionCover1,
          content: `
             人民网编者按: 千万IP创科普，共筑科技强国梦。为普及科学知识，弘扬科学精神，提高全民科学文化素质，助力实现高水平科技自立自强，中国科协科普部与人民网联合策划推出“见证科技之路”主题报道，聚焦国家科技战略政策、重要科技奖项、“卡脖子”关键核心技术等，深入基层一线开展采访调研，推出一批生动鲜活的科普精品，大力弘扬科学家精神，为建设科技强国汇聚磅礴力量。
            `,
          url: 'https://mp.weixin.qq.com/s/QrcXuybfgOudmlqb6Cyv3w'
        },
        {
          time: '2024年06月28日',
          title: '企业风采 | 南京复创入选Pegasus投融研究所《产品数字护照行业研究报告》',
          backgroundImg: sectionCover2,
          content: `
            近日，天马科创投（Pegasus Tech Ventures）正式发布《产品数字护照行业研究报告》。南京复创与英国 Circulor、加拿大 OPTEL 公司、德国 Spherity、荷兰Circularise等全球领先产业链及数字护照服务商作为重点企业代表入选《报告》。
            `,
          url: 'https://mp.weixin.qq.com/s/nBjOEHOiwTxThebM5V8uQw'
        },
        {
          time: '2024年04月20日',
          title: '活动回顾丨南京复创参与中国电池工业协会电池护照工作推进会并做主题汇报',
          backgroundImg: sectionCover3,
          content: `
            2024年4月20日，中国电池工业协会八届五次理事会暨电池护照工作推进会在北京圆满举办，工信部消费品工业司副司长李强、中国电池工业协会理事长刘宝生、中国电池工业协会会员部主任吴丹、南京玄武高新技术产业集团有限公司副总经理王涛、中国电池工业协会综合业务部副主任宋翊、南京复创董事长付金国等数百位行业专家与企业代表参与会议。
            `,
          url: 'https://mp.weixin.qq.com/s/yWEVK1DBbXoVbsKwhJmmrw'
        }
      ]
    };
  },
  created() {
     this.listenObserverWidth(750);
  },
  methods: {
    // 监听可视窗口宽度
    listenObserverWidth(mobileWidth) {
       const resizeObserver = new ResizeObserver(entries => {
          for(let entry of entries) {
             if(entry.contentRect.width <= mobileWidth) {
                this.isMobile = true;
             } else {
                this.isMobile = false;
             }
          }
       });
       resizeObserver.observe(document.body);
    },
    convertUrl(url) {
      window.open(url, '_blank')
    }
  }
};
</script>
<style lang="less" scoped>
@import url('./index.less');
</style>
