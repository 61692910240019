<template>
    <div class="aboutUs-page">
        <!-- 关于我们 -->
        <div class="aboutUs-header" :style="`background-image: url(${frameBackground})`">
            <div class="aboutUs-header_text">
                <p>关于我们</p>
                <p>ABOUT US</p>
            </div>
        </div>
        <!-- 中国产品数字护照网 -->
        <div class="aboutUs-content" :style="`background-image: url(${decorationBackground})`">
            <div class="aboutUs-title">
                <img :src="decorationIcon" />
                <label>中国产品数字护照网</label>
            </div>
            <div class="aboutUs-content_tip">
                <label>面向全球, 创建产品数字护照产业生态</label>
            </div>
            <div class="aboutUs-content_list">
               <div class="content_list-item" v-for="(item, index) in passportList" :key="index">
                   <img :src="item.icon" />
                   <div class="list-item_box">
                     <span>{{ item.label }}</span>
                   </div>
               </div>
            </div>
            <div class="aboutUs-content_frame">
                <img :src="framePassportImg" />
            </div>
        </div>
        <!-- 公开活动 -->
        <div class="aboutUs-activity">
            <div class="aboutUs-title">
                <img :src="decorationIcon" />
                <label>公开活动</label>
            </div>
            <div class="aboutUs-activity_list">
                <div class="activity_list-item" v-for="(item, index) in cardList" :key="index">
                    <img :src="item.img" />
                    <div class="list-item_box">
                        <div class="item_box-label"><label>{{ item.label }}</label></div>
                        <div class="item_box-expandLabel" v-if="item.expandLabel">
                            <label>{{ item.expandLabel }}</label>
                        </div>
                        <div class="item_box-footer">
                            <span>{{item.time }}</span>
                            <span>查看详情</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <footer-nav />
        <form-nav v-if="$webType != 'cbia'" />
    </div>
</template>
<script>
import frameCardZeroImg from '@/assets/images/aboutUs/mobile/frameCardZero.png';
import frameCardOneImg from '@/assets/images/aboutUs/mobile/frameCardOne.png';
import frameCardTwoImg from '@/assets/images/aboutUs/mobile/frameCardTwo.png';
import frameCorrectIcon from '@/assets/images/aboutUs/mobile/frameCorrect.png';
import framePassportImg from '@/assets/images/aboutUs/mobile/framePassport.png';
import decorationIcon from '@/assets/images/aboutUs/mobile/decoration.png';
import decorationBackground from '@/assets/images/aboutUs/mobile/decorationBackground.png';
import frameBackground from '@/assets/images/aboutUs/mobile/frameBackground.png';
export default {
    name: 'mobileAboutUs',
    data() {
        return {
            frameCardZeroImg,
            frameCardOneImg,
            frameCardTwoImg,
            frameCorrectIcon,
            framePassportImg,
            decorationIcon,
            frameBackground,
            decorationBackground,
            passportList: [
                {
                   icon: frameCorrectIcon,
                   label: '中国产品数字护照网由中国电池工业协会及中国信通院指导' 
                },
                {
                    icon: frameCorrectIcon,
                    label: '由国家工业互联网标识解析二级节点建设单位所建立运营'
                },
                {
                    icon: frameCorrectIcon,
                    label: '专注中国产品数字护照的专业门户，是中国产品数字护照体系服务的客户端统一入口'
                }
            ],
            cardList: [
                {
                   img: frameCardZeroImg,
                   label: '中国电池工业协会、中国信息通信研究院与电池产业头部企业联合发布《推进中国电池数字护照体系建设倡议书》',
                   time: '2023.03.11-06.10'
                },
                {
                   img: frameCardOneImg,
                   label: '【电池护照】欧盟法规背景下，构建我国电池数字护照迫在眉睫！',
                   expandLabel: '2023年12月，全球电池联盟（GBA）正式批准南京复创作为全球组织的新成员加入。',
                   time: '2023.03.11-06.10'
                },
                {
                   img: frameCardTwoImg,
                   label: '中国电池工业协会联合发布《中国电池数字护照白皮书》',
                   expandLabel: '2024年4月20日，中国电池工业协会八届五次理事会暨电池护照工作推进会在北京顺利召开。',
                   time: '2023.03.11-06.10'
                }
            ]
        }
    },
    methods: {

    }
}
</script>
<style lang="less" scoped>
@import url('./index.less');
</style>
