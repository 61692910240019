<template>
  <div class="faq-page">
    <div
      class="faq-header"
      :style="`background-image: url(${groupBackgroundImg})`"
    >
      <div class="faq-header_text">
        <p>关于DPP的问题? 请参阅我们的</p>
        <p>中国电池护照技术发展报告</p>
      </div>
    </div>
    <!-- 常见问题 -->
    <div
      class="faq-issue"
      :style="`background-image: url(${wrapperBackgroundImg})`"
    >
      <div class="faq-title">
        <img :src="decorationIcon" />
        <label>常见问题</label>
      </div>
      <div class="faq-issue_list">
        <div
          class="issue_list-item"
          v-for="(item, index) in collapseList"
          :key="index"
        >
          <div class="list-item_title" @click="collapse(index, item)">
            <label>{{ item.serial }}</label>
            <label>{{ item.label }}</label>
            <img
              :class="[item.collapsed ? 'img-collapseUp' : 'img-collapseDown']"
              :src="arrowIcon"
            />
          </div>
          <div class="list-item_content" v-if="item.collapsed">
            <div class="item_content-p" v-if="Array.isArray(item.content)">
              <p v-for="(subItem, subIndex) in item.content" :key="subIndex">
                {{ subItem }}
              </p>
            </div>
            <div v-else>
              <label>{{ item.content }}</label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer-nav />
  </div>
</template>
<script>
import arrowIcon from "@/assets/images/faq/mobile/arrowIcon.png";
import decorationIcon from "@/assets/images/faq/mobile/decorationIcon.png";
import groupBackgroundImg from "@/assets/images/faq/mobile/groupBackgroundImg.png";
import wrapperBackgroundImg from "@/assets/images/faq/mobile/wrapperBackgroundImg.png";
export default {
  name: "mobileFaq",
  data() {
    return {
      arrowIcon,
      decorationIcon,
      groupBackgroundImg,
      wrapperBackgroundImg,
      collapseList: [
        {
          serial: "01",
          label: "什么是产品数字护照?",
          content:
            "产品数字护照的本质是一组特定于产品的、可通过数据载体进行电子访问的数据集，通过数据载体链接唯一标识符，在跨国贸易和流通中证明产品的产地、身份及可持续水平。",
          collapsed: true,
        },
        {
          serial: "02",
          label: "什么是中国产品数字护照网?",
          content:
            "中国产品数字护照网在中国电池工业协会及中国信通院指导下，由国家工业互联网标识解析二级节点建设单位南京复创建立运营，是构建产业链供应链 协同的数据共享空间，是专注中国产品数字护照的技术平台，是中国产品数字护照体系服务 的窗口。",
          collapsed: true,
        },
        {
          serial: "03",
          label: "中国产品数字护照网目标是什么？",
          content:
            "以行业项目为切入点,定义中国产品数字护照体系标准，塑造中国产品数字护照的未来。",
          collapsed: true,
        },
        {
          serial: "04",
          label: "如何理解中国产品数字护照体系？",
          content:
            "中国产品数字护照体系是在数字化和绿色化协同发展的背景下，整合工业互联网标识、区块链等新一代信息技术，全面考虑产品生命周期管理和可持续发展需求，推出的创新型数字认证系统，对推动产品循环利用和绿色生产、促进产业数字化和绿色化协同发展、推动产业可持续发展和数字创新具有重要作用。",
          collapsed: true,
        },
        {
          serial: "05",
          label: "如何加入网站?",
          content: ["联系电话：400-106-2918", "联系邮箱：chdpp@fc3de.com"],
          collapsed: true,
        },
        {
          serial: "06",
          label: "什么是数字护照联合验证项目?",
          content: "针对企业的产品数字护照部署验证项目。",
          collapsed: true,
        },
        {
          serial: "07",
          label: "中国产品数字护照网提供什么样的解决方案？",
          content:
            "提供关于产品数字护照的软硬件系统平台及咨询、规划、设计、实施、认证服务的综合解决方案。",
          collapsed: true,
        },
        {
          serial: "08",
          label: "目前, 哪些行业需要提供产品数字护照?",
          content: `目前、在全球范围内，产品数字护照将在电池、电子产品、纺织、钢铁等行业率先落地。
                   《新欧盟电池和废电池法规》中提出，2027年2月18日起，投放市场或投入使用的LMT电池、容量大于2kwh的工业电池和电动汽车电池应具有数字护照。
                    欧洲议《可持续循环纺织品策略》决议通过，贸易中纺织品DPP强制性使用的启动时间在2030年。
                    另外，欧盟碳边境调节机制已经推行，钢铁行业作为CBAM重点涉及领域，对于全生命周期碳数据管理提出新的更高要求。预计到2030年，欧盟市场上除食品、饲料和医药产品以外，包括钢材产品及钢材下游终端产品的所有产品都要满足产品数字护照的要求。`,
          collapsed: true,
        },
      ],
    };
  },
  methods: {
    collapse(index, item) {
      let handleItem = null;
      const funcItem = (collapsed) => {
        return {
          ...item,
          collapsed,
        };
      };
      if (item.collapsed) {
        handleItem = funcItem(false);
      } else {
        handleItem = funcItem(true);
      }
      this.collapseList = this.collapseList.map((element, eleIndex) =>
        eleIndex == index ? handleItem : element
      );
    },
  },
};
</script>
<style lang="less" scoped>
@import url("./index.less");
</style>