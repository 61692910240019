<template>
    <div class="batteryIndustry-page">
        <!-- 头部 -->
        <div class="batteryIndustry-header" :style="`background-image: url(${headerImg})`">
            <div class="batteryIndustry-header_text">
                <p>项目解决方案</p>
                <p>Project Solution</p>
            </div>
        </div>
        <!-- 电池行业 -->
        <div class="batteryIndustry-cardOne">
            <div class="batteryIndustry-title">
                <img :src="decorationIcon" />
                <label>电池行业</label>
            </div>
            <div class="batteryIndustry-content">
                <div class="batteryIndustry-content_label">
                    欧盟《新电池法》生效，推进电池行业发展
                </div>
                <div class="batteryIndustry-content_text">
                    2023年8月17日，《欧盟新电池法EU2023/1542》正式落地生效。其核心思想是电池全生命周期管理，从可持续性、安全、标签和信息的角度，对电池行业的制造商责任，回收处理，尽职调查和绿色公共采购做出了一系列规定。 
                </div>
                <div class="batteryIndustry-content_text">
                    法规要求，自 2027 年起，动力电池出口到欧洲需要持有符合要求的“数字护照”。法规内容显示，“数字护照”用以记录电池的制造商、材料成分、技术规格、碳足迹和供应链等信息。
                </div>
                <div class="batteryIndustry-content_img">
                    <img :src="cardImg" />
                </div>
            </div>
        </div>
        <!-- 电池数字护照作为DPP领域先行者，意义重大 -->
        <div class="batteryIndustry-cardTwo">
            <div class="batteryIndustry-title">
                <img :src="decorationIcon" />
                <label>电池数字护照作为DPP领域先行者，意义重大</label>
            </div>
            <div class="batteryIndustry-list">
                <div class="batteryIndustry-list_item" v-for="(item, index) in pannelList" :key="index">
                    <div class="list_item-text">
                        {{ item.text }}
                    </div>
                    <div class="list_item-img">
                        <img :src="item.icon" />
                    </div>
                </div>
            </div>
        </div>
        <!-- 法规时间轴 -->
        <div class="batteryIndustry-cardTree">
            <img :src="timeLineImg" />
        </div>
        <!-- 电池数字护照赋能行业价值 -->
        <div class="batteryIndustry-cardFour">
            <div class="batteryIndustry-title">
                <img :src="decorationIcon" />
                <label>电池数字护照赋能行业价值</label>
            </div>
            <div class="batteryIndustry-cards">
                <div class="batteryIndustry-cards_item" v-for="(item, index) in cardList" :key="index">
                    <div class="cards_item-header" @click="collapse(index, item, 'cardList')">
                        <img :src="item.icon" />
                        <label>{{ item.title }}</label>
                        <img :class="['arrow-icon', item.collapsed ? 'img-collapseUp' : 'img-collapseDown']" :src="arrowIcon" />
                    </div>
                    <div class="cards_item-content" v-if="item.collapsed">{{ item.content }}</div>
                </div>
            </div>
        </div>
        <!-- 电池数字护照助力电池企业价值 -->
        <div class="batteryIndustry-cardFive">
            <div class="batteryIndustry-title">
                <img :src="decorationIcon" />
                <label>电池数字护照助力电池企业价值</label>
            </div>
            <div class="batteryIndustry-enterprise">
                <div class="batteryIndustry-enterprise_item" v-for="(item, index) in enterpriseList" :key="index">
                    <div class="enterprise_item-header" @click="collapse(index, item, 'enterpriseList')">
                       <label>{{ item.title }}</label>
                       <img :class="['arrow-right_icon', item.collapsed ? 'img-collapseUp' : 'img-collapseDown']" :src="arrowIcon" />
                    </div>
                    <div class="enterprise_item-content" v-if="item.collapsed">{{ item.content }}</div>
                </div>
            </div>
        </div>
        <!-- 项目方案 -->
        <div class="batteryIndustry-cardSix">
            <div class="batteryIndustry-title">
                <img :src="decorationIcon" />
                <label>项目方案</label>
            </div>
            <div class="batteryIndustry-solutions">
                <div class="batteryIndustry-solutions_item" v-for="(item, index) in solutionList" :key="index">
                    <div class="solutions_item-header" @click="collapse(index, item, 'solutionList')">
                        <label>{{ item.title }}</label>
                        <img :class="['arrow-right_icon', item.collapsed ? 'img-collapseUp' : 'img-collapseDown']" :src="arrowIcon" />
                    </div>
                    <div class="solutions_item-content" v-if="item.collapsed">{{ item.content }}</div>
                </div>
            </div>
        </div>
        <!-- 联系我们 -->
        <footer-nav />
    </div>
</template>
<script>
import arrowIcon from '@/assets/images/batteryIndustry/mobile/arrowIcon.png';
import cardOneIcon from '@/assets/images/batteryIndustry/mobile/cardOneIcon.png';
import cardTwoIcon from '@/assets/images/batteryIndustry/mobile/cardTwoIcon.png';
import cardThreeIcon from '@/assets/images/batteryIndustry/mobile/cardThreeIcon.png';
import decorationIcon from '@/assets/images/batteryIndustry/mobile/decorationIcon.png';
import timeLineImg from '@/assets/images/batteryIndustry/mobile/timeLineImg.png';
import backgroundwrapperImg from '@/assets/images/batteryIndustry/mobile/backgroundwrapperImg.png';
import cardImg from '@/assets/images/batteryIndustry/mobile/cardImg.png';
import headerImg from '@/assets/images/batteryIndustry/mobile/headerImg.png';
import pannelOneIcon from '@/assets/images/batteryIndustry/mobile/pannelOneIcon.png';
import pannelTwoIcon from '@/assets/images/batteryIndustry/mobile/pannelTwoIcon.png';
export default {
    name: 'mobileBattryIndustry',
    data() {
        return {
            decorationIcon,
            arrowIcon,
            timeLineImg,
            backgroundwrapperImg,
            cardImg,
            headerImg,
            pannelList: [
                {
                    icon: pannelOneIcon,
                    text: '双碳背景下，新能源赛道成国际国内热点，全球动力电池产业呈迅速增长态势，相关产业链也面临着诸多ESG挑战，催生着电池产品数字护照成为DPP领域的先行者，赋能电池企业实现数字化转型和ESG转型，推动电池全供应链透明化数字管理。'
                },
                {
                    icon: pannelTwoIcon,
                    text: '电池产品数字护照也将作为政府监管和社会监督的有力抓手，可成为促进电池产业低碳、循环和可持续发展的重要政策工具，对于推动电池行业绿色可持续发展，确保我国电池领域全球竞争力具有重大意义。'
                }
            ],
            cardList: [
                {
                    icon: cardOneIcon,
                    title: '助力产业链协同',
                    content: '通过数字化技术打造电池数字护照，实现研发协同、设计协同、生产协同、物流协同等，利用统一的数据格式和标准，打通企业间的数据壁垒，整个产业的运作更高效、更智能。',
                    collapsed: true,
                },
                {
                    icon: cardTwoIcon,
                    title: '提升供应链韧性',
                    content: '电池数字护照构建一个递归网络，通过将供应链相关企业的数据拉通，增强供应链的透明度、适应能力和抗压性，从而保证供应链的韧性。',
                    collapsed: true,
                },
                {
                    icon: cardThreeIcon,
                    title: '助力产业链协同',
                    content: '电池数字护照将数据披露权限掌握在企业手上，针对不同角色，开放不同数据内容。并且，电池护数字照体系设计利用数据加密、区块链等技术从根本上保证数据安全、可信交互。',
                    collapsed: true,
                }
            ],
            enterpriseList: [
                {
                    title: '帮助电池企业数绿双转',
                    content: '实现电池数字护照，背后需要一个高度完整得数字化系统和绿色化系统，构建一个可信, 安全, 实时更新的数据平台，将所有数据高效汇聚。',
                    collapsed: false,
                },
                {
                    title: '提高产品得高可信',
                    content: '在产品的可信度方面，是一个双向互动的过程。数字护照记录了产品的完整信息，更方便用户查看，企业也可以了解更多用户的真实想法，使得用户安心, 客户放心, 给予企业信心。',
                    collapsed: false,
                },
                {
                    title: '促进循环经济',
                    content: '通过电池数字护照全程监测跟踪电池的使用情况，可以准确获取电池信息，做更好的梯次使用，回收再造，乃至报废处理。',
                    collapsed: false,
                },
                {
                    title: '助力合规体系化',
                    content: '通过电池数字护照体系，汇聚企业各类数据, 定制化模板一键生成，节约大量人力成本，提高效率与用户认可度。',
                    collapsed: false,
                },
                {
                    title: '保障数据资产及绿色资产',
                    content: '实现电池数字护照离不开数据治理。在数据治理过程中，对数据进行分类，更好的发现问题，优化激励模型，将数据更好的用于产品设计,生成制造中。',
                    collapsed: false,
                }
            ],
            solutionList: [
                {
                    title: '护照申请',
                    content: '企业进行电池护照申请，通过电池护照技术，用户可以查询电池历史信息并进行评估，以确保电池的质量和性能。',
                    collapsed: false,
                },
                {
                    title: '数据采集',
                    content: '通过数据收集，开启产品五大生命周期阶段核算，最终编制一份产品碳足迹报告。',
                    collapsed: false,
                },
                {
                    title: '数据核验',
                    content: '系统后台自动进行数据核验，确保数据的准确性, 完整性和可靠性。',
                    collapsed: false,
                },
                {
                    title: '数据标识',
                    content: '通过赋提供“一物一码”服务，为每个产品配备唯一碳足迹二维码标识，实现在产业链中产品低碳属性的可查询, 产品流动情况的可追踪。',
                    collapsed: false,
                },
                {
                    title: '三方认证',
                    content: '认证通过的产品碳足迹结果，通过公信认证机构，发放数据认证报告及认可轻碳标识。',
                    collapsed: false,
                },
                {
                    title: '护照发行',
                    content: '三方行业知名机构参与平台站台背书，为企业提高报告公信力，确保电池能够在欧洲市场上流通。',
                    collapsed: false,
                },
                {
                    title: '护照溯源',
                    content: '通过登录产品碳足迹追溯服务平台可生成的专属追溯二维码，通过微信扫一扫，即可打开小程序展示产品碳足迹追溯分析。',
                    collapsed: false,
                },
                {
                    title: '区块链存证',
                    content: '通过区块链上链存证的碳足迹报告，皆可通过碳账本公示披露平台进行公示查询。',
                    collapsed: false,
                }
            ]
        }
    },
    methods: {
        collapse(index, item, key) {
            let handleItem = null;
            const funcItem = (collapsed) => {
                return {
                    ...item,
                    collapsed
                }
            };
            if(item.collapsed) {
                 handleItem = funcItem(false);
            } else {
                 handleItem = funcItem(true);
            }
            this[key] = this[key].map((element, eleIndex) =>
                eleIndex == index ? handleItem : element
            );
        }
    }
}
</script>
<style lang="less" scoped>
@import url('./index.less');
</style>