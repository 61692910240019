<template>
    <div class="technicalStandard-page">
        <!-- 技术标准 -->
        <div class="technicalStandard-header" :style="`background-image: url(${backgroundImg})`">
            <div class="technicalStandard-header_text">
                <p>技术标准</p>
                <p>Technical standard</p>
            </div>
        </div>
        <!-- 技术标准列表 -->
        <div class="technicalStandard-content" :style="`background-image: url(${decorationBackgroundImg})`">
            <div class="technicalStandard-title">
                <img :src="decorationIcon" />
                <label>技术标准</label>
            </div>
            <div class="technicalStandard-content_list">
                <div class="content_list-item" v-for="(item, index) in cardList" :key="index">
                    <div class="list-item_block">
                        <img :src="item.img" />
                        <label>{{ item.title }}</label>
                    </div>
                    <div class="list-item_box" v-for="(subItem, subIndex) in item.list" :key="subIndex">
                        <img :src="dotIcon" />
                        <div class="item_box-inline">
                            <span>{{ subItem }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import backgroundImg from '@/assets/images/technicalStandard/mobile/backgroundImg.png';
import decorationBackgroundImg from '@/assets/images/technicalStandard/mobile/decorationBackgroundImg.png';
import decorationIcon from '@/assets/images/technicalStandard/mobile/decorationIcon.png';
import qrcodeIcon from '@/assets/images/technicalStandard/mobile/qrcodeIcon.png';
import listIcon from '@/assets/images/technicalStandard/mobile/listIcon.png';
import dataSourceIcon from '@/assets/images/technicalStandard/mobile/dataSourceIcon.png';
import fileIcon from '@/assets/images/technicalStandard/mobile/fileIcon.png';
import searchIcon from '@/assets/images/technicalStandard/mobile/searchIcon.png';
import dotIcon from '@/assets/images/technicalStandard/mobile/dotIcon.png';
export default {
    name: 'mobileTechnicalStandard',
    data() {
        return {
            backgroundImg,
            decorationBackgroundImg,
            decorationIcon,
            dotIcon,
            cardList: [
                {
                    img: qrcodeIcon,
                    title: '电池数字护照码标准',
                    list: [
                        '每个电池数字护照的电池数字护照码应具备唯一性。',
                        '应由单独可信的生码体系确保电池数字护照码的唯一性、安全性和国际流通性。如工业互联网标识体系。'
                    ]
                },
                {
                    img: listIcon,
                    title: '电池数字护照数据核验标准',
                    list: [
                        '电池数字护照应通过相关技术手段核验其数据的真实性、唯一性以及数据的不可篡改。',
                        '将电池投放市场的经济运营主体，可使用区块链技术确保电池数字护照的真实性、唯一性和数据的不可篡改，并通过相关核验平台提供核验数据的技术手段。'
                    ]
                },
                {
                    img: dataSourceIcon,
                    title: '电池数字护照动态数据更新标准',
                    list: [
                        '应该至少每天更新一次。并且在特定目的时，需要更频繁地更新。'
                    ]
                },
                {
                    img: fileIcon,
                    title: '电池数字护照数据存储期限标准',
                    list: [
                        '经济经营者应在与电池数字护照相关的电池上市10年内，保存电池数字护照数据。',
                        '同时，在电池报废前维护电池数字护照的链接，使其可正常访问。'
                    ]
                },
                {
                    img: searchIcon,
                    title: '电池数字护照数据访问标准',
                    list: [
                        '电池数字护照中包含的所有信息应基于开放标准，并采用互操作格式，可通过开放互操作数据交换网络传输，无供应商网络锁定。',
                        '电池数字护照应满足机器可读、结构化和可搜索的要求。'
                    ]
                }
            ]
        }
    }
}
</script>
<style lang="less" scoped>
@import url('./index.less');
</style>