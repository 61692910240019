<template>
    <component :is="componentTag" />
  </template>
  <script>
  import webCarbonManage from "./web";
  import mobileCarbonManage from "./mobile";
  export default {
    components: {
      webCarbonManage,
      mobileCarbonManage,
    },
    data() {
      return {
        isMobile: false,
        componentTag: "",
      };
    },
    created() {
      this.listenObserverWidth(750);
    },
    methods: {
      // 监听可视窗口宽度
      listenObserverWidth(mobileWidth) {
        const resizeObserver = new ResizeObserver((entries) => {
          for (let entry of entries) {
            if (entry.contentRect.width <= mobileWidth) {
              this.componentTag = "mobileCarbonManage";
            } else {
              this.componentTag = "webCarbonManage";
            }
          }
        });
        resizeObserver.observe(document.body);
      },
    },
  };
  </script>