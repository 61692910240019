<template>
  <component :is="componentTag" />
</template>
<script>
import webPolicyComment from './web';
import mobilePolicyComment from './mobile';
export default {
  components: {
    webPolicyComment,
    mobilePolicyComment
  },
  data() {
    return {
       isMobile: false,
       componentTag: ''
    };
  },
  created() {
     this.listenObserverWidth(750);
  },
  methods: {
    // 监听可视窗口宽度
    listenObserverWidth(mobileWidth) {
       const resizeObserver = new ResizeObserver(entries => {
          for(let entry of entries) {
             if(entry.contentRect.width <= mobileWidth) {
                this.componentTag = 'mobilePolicyComment';
             } else {
                this.componentTag = 'webPolicyComment';
             }
          }
       });
       resizeObserver.observe(document.body);
    }
  },
};
</script>