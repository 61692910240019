<template>
  <div class="page flex-col">
    <!-- header -->
    <div class="section_header flex-col">
      <div class="header-wrapper flex-col">
        <div class="header-wrapper_1 flex-row">
          <span class="text_1">项目解决方案</span>
        </div>
        <div class="header-wrapper_2 flex-row justify-between">
          <span class="text_2">Project&nbsp;Solution</span>
        </div>
      </div>
    </div>
    <!-- middle -->
    <div class="section_middle flex-col">
      <!-- 标题 -->
      <div class="middle-title flex-row justify-between">
        <div class="anrchor_class anrchor_class_special" id="unionBackground"></div>
        <img class="middle-title_icon" referrerpolicy="no-referrer" :src="decorationIcon" />
        <span class="middle-title_label">电池行业</span>
      </div> 
      <!-- 副标题 -->
      <div class="middle-subTitle flex-row justify-between">
        <div class="middle-subTitle_style flex-col"></div>
        <span class="middle-subTitle_label">欧盟《新电池法》生效，推进电池行业发展</span>
      </div>
      <!-- 文本内容1 -->
      <div class="middle-text1">
        <span class="middle-text1_1">2023年8月17日，《欧盟新电池法规(EU)2023/1542》正式落地生效。其核心思</span>
        <span
          class="middle-text1_2">想是电池全生命周期管理，从可持续性、安全、标签和信息的角度，对电池行业的制造商责任，回收处理，尽职调查和绿色公共采购做出了一系列规定。<br />&nbsp;法规要求，自&nbsp;2027&nbsp;年起，动力电池出口到欧洲需要持有符合要求的“数字护照”。法规内容显示，“数字护照”用以记录电池的制造商、材料成分、技术规格、碳足迹和供应链等信息。</span>
      </div>
      <!-- 文本样式 -->
      <div class="middle-style flex-col"></div>
      <!-- 卡片标题 -->
      <div class="middle-cardTitle flex-row justify-between">
        <div class="anrchor_class" id="marketSituation"></div>
        <img class="middle-cardTitle_img" referrerpolicy="no-referrer" :src="decorationIcon" />
        <span class="middle-cardTitle_label">电池数字护照作为DPP领域先行者，意义重大</span>
      </div>
      <!-- 卡片 -->
      <div class="middle-card flex-row justify-between">
        <div class="middle-card_left flex-row">
          <img class="card_left-img" referrerpolicy="no-referrer" :src="cardOneIcon" />
          <span
            class="card_left-label">双碳背景下，新能源赛道成国际国内热点，全球动力电池产业呈迅速增长态势，相关产业链也面临着诸多ESG挑战，催生着电池产品数字护照成为DPP领域的先行者，赋能电池企业实现数字化转型和ESG转型，推动电池全供应链透明化数字管理。</span>
        </div>
        <div class="middle-card_right flex-row">
          <img class="card_right-img" referrerpolicy="no-referrer" :src="cardTwoIcon" />
          <span
            class="card_right-label">电池产品数字护照也将作为政府监管和社会监督的有力抓手，可成为促进电池产业低碳、循环和可持续发展的重要政策工具，对于推动电池行业绿色可持续发展，确保我国电池领域全球竞争力具有重大意义。</span>
        </div>
      </div>
      <img class="industry-image" referrerpolicy="no-referrer" :src="industryBackgroundImg" />
      <div class="industry-text flex-col">
        <span class="industry-text_1">欧盟新电池法规</span>
        <span class="industry-text_2">（2023年）</span>
      </div>
      <!-- 时间轴 -->
      <div class="middle-timeline flex-col">
        <div class="middle-timeline_wrapper flex-row">
          <span class="timeline_wrapper-text">欧盟法规中，电池数字护照实施关键节点时间轴</span>
        </div>
        <div class="middle-timeline_block flex-row">
          <!-- 区块 -->
          <div class="timeline_block-item flex-row justify-between" v-for="(item, index) in timelines" :key="index"
            :style="item.style.block">
            <img class="timeline_block-image" referrerpolicy="no-referrer" :src="item.lineIcon"
              :style="item.style.img" />
            <div class="timeline_block-text flex-col justify-between" :style="item.style.text">
              <span class="block-text_1">{{ item.time }}</span>
              <span class="block-text_2">{{ item.label.replace(/[,.\s<\s*br\ /s*>]/g, '\n') }}</span>
            </div>
          </div>
        </div>
        <!-- 线 -->
        <div class="middle-timeline_arrow">
          <img :src="lineArrowImg" />
        </div>
      </div>
    </div>
    <!-- bottom -->
    <div class="section_bottom flex-col">
      <div class="bottom-title flex-row justify-between">
        <div class="anrchor_class" id="valueSignificance"></div>
        <img class="bottom-title_img" referrerpolicy="no-referrer" :src="decorationIcon" />
        <span class="bottom-title_label">电池数字护照赋能行业价值</span>
      </div>

      <!-- 电池数字护照赋能行业价值 -->
      <div class="battery-card flex-row">
        <div :class="['card-box flex-col', cardIndex == index ? 'card-active' : null]"
          v-for="(item, index) in batteryValues" :key="index">
          <div :class="['card-box_block flex-row']" :style="`background: url(${item.backgroundImg}) 100% no-repeat`">
            <div class="box_block-div flex-col justify-between">
              <img class="block-div_img" referrerpolicy="no-referrer" :src="item.batteryImg" />
              <img class="block-div_img" referrerpolicy="no-referrer" :src="item.batteryActiveImg" />
              <span class="block-div_label">{{ item.batteryTitle }}</span>
              <span class="block-div_label1">{{ item.batteryActiveLabel }}</span>
              <img class="card-active_img" referrerpolicy="no-referrer" :src="item.backgroundActiveImg" />
            </div>
          </div>
          <div class="card-box_block1 flex-row">
            <div class="box_block1-wrapper flex-col" v-for="(subItem, subIndex) in item.batteryTags" :key="subIndex">
              <span class="block1-wrapper_label">{{ subItem }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="bottom-title flex-row justify-between">
        <img class="bottom-title_img" referrerpolicy="no-referrer" :src="decorationIcon" />
        <span class="bottom-title_label">电池数字护照助力电池企业价值</span>
      </div>
      <!-- 电池数字护照助力电池企业价值 -->
      <div class="battery-images flex-col">
        <!-- 顶部 -->
        <div class="battery-images_top flex-row">
          <div class="images_top-one flex-row shadow-transition">
            <div class="top-one_wrapper flex-col shadow-transition_header">
              <span class="one_wrapper-text">帮助电池企业数绿双转</span>
              <div class="shadow-transition_label">
                实现电池数字护照，背后需要一个高度完整的数字化系统和绿色化系统，构建一个可信、安全、实时更新的数据平台，将所有数据高效汇聚。
              </div>
            </div>
          </div>
          <div class="images_top-two flex-row shadow-transition">
            <div class="top-two_wrapper flex-col shadow-transition_header">
              <span class="two_wrapper-text">提高产品的高可信</span>
              <div class="shadow-transition_label">
                在产品的可信度方面，是一个双向互动的过程。数字护照记录了产品的完整信息，更方便用户查看，企业也可以了解更多用户的真实想法，使得用户安心、客户放心、给予企业信心。
              </div>
            </div>
          </div>
          <div class="images_top-three flex-row shadow-transition">
            <div class="top-three_wrapper flex-col shadow-transition_header">
              <span class="three_wrapper-text">促进循环经济</span>
              <div class="shadow-transition_label">
                通过电池数字护照全程监测跟踪电池的使用情况，可以准确获取电池信息，做更好的梯次利用，回收再造，乃至报废处理。
              </div>
            </div>
          </div>
        </div>
        <!-- 底部 -->
        <div class="battery-images_bottom flex-row">
          <div class="images_bottom-one flex-row shadow-transition">
            <div class="bottom-one_wrapper flex-col shadow-transition_header">
              <span class="one_wrapper-text">助力合规体系化</span>
              <div class="shadow-transition_label">
                通过电池数字护照体系，汇聚企业各类数据、定制化模板一键生成，节约大量人力成本，提高效率与用户认可度。
              </div>
            </div>
          </div>
          <div class="images_bottom-two flex-row shadow-transition">
            <div class="bottom-two_wrapper flex-col shadow-transition_header">
              <span class="two_wrapper-text">保障数据资产及绿色资产</span>
              <div class="shadow-transition_label">
                实现电池数字护照离不开数据治理。在数据治理过程中，对数据进行分类，更好的发现问题，优化激励模型，将数据更好的用于产品设计、生产制造中。
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom-title flex-row justify-between">
        <div class="anrchor_class" id="projectPlan"></div>
        <img class="bottom-title_img" referrerpolicy="no-referrer" :src="decorationIcon" />
        <span class="bottom-title_label">项目方案</span>
      </div>
      <div class="battery-list flex-warp justify-between">
        <div class="list-group" v-for="(item, index) in projectSolutions" :key="index">
          <div class="list-group_item flex-row">
            <div class="group_item-wrapper flex-row justify-between">
              <div class="item-wrapper_div flex-col justify-between">
                <span class="wrapper_div-text1">{{ item.projectTitle }}</span>
                <span class="wrapper_div-text2">{{ item.projectLabel }}<br /></span>
              </div>
              <img class="item-wrapper_img" referrerpolicy="no-referrer" :src="item.projectIcon" />
              <div class="item-wrapper_line"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer-nav />
  </div>
</template>
<script>
import firstOneImg from "@/assets/images/batteryIndustry/first_one.png";
import firstTwoImg from "@/assets/images/batteryIndustry/first_two.png";
import firstThreeImg from "@/assets/images/batteryIndustry/first_three.png";
import secondOneImg from "@/assets/images/batteryIndustry/second_one.png";
import secondTwoImg from "@/assets/images/batteryIndustry/second_two.png";
import lineArrowImg from "@/assets/images/batteryIndustry/line_arrow.png";
import decorationIcon from '@/assets/images/home/decoration.png';
import cardOneIcon from '@/assets/images/batteryIndustry/card_one.png';
import cardTwoIcon from '@/assets/images/batteryIndustry/card_two.png';
import industryBackgroundImg from '@/assets/images/batteryIndustry/industry_background.png';

import batteryImgOne from '@/assets/images/batteryIndustry/battery_img_one.png';
import backgroundImgOne from '@/assets/images/batteryIndustry/background_img_one.png';
import batteryActiveImgOne from '@/assets/images/batteryIndustry/battery_active_img_one.png';
import backgroundActiveImgOne from '@/assets/images/batteryIndustry/background_active_img_one.png';

import batteryImgTwo from '@/assets/images/batteryIndustry/battery_img_two.png';
import backgroundImgTwo from '@/assets/images/batteryIndustry/background_img_two.png';
import batteryActiveImgTwo from '@/assets/images/batteryIndustry/battery_active_img_two.png';
import backgroundActiveImgTwo from '@/assets/images/batteryIndustry/background_active_img_two.png';

import batteryImgThree from '@/assets/images/batteryIndustry/battery_img_three.png';
import backgroundImgThree from '@/assets/images/batteryIndustry/background_img_three.png';
import batteryActiveImgThree from '@/assets/images/batteryIndustry/battery_active_img_three.png';
import backgroundActiveImgThree from '@/assets/images/batteryIndustry/background_active_img_three.png';

import projectIconOne from '@/assets/images/batteryIndustry/project_icon_one.png';
import projectIconTwo from '@/assets/images/batteryIndustry/project_icon_two.png';
import projectIconThree from '@/assets/images/batteryIndustry/project_icon_three.png';
import projectIconFour from '@/assets/images/batteryIndustry/project_icon_four.png';
import projectIconFive from '@/assets/images/batteryIndustry/project_icon_five.png';
import projectIconSix from '@/assets/images/batteryIndustry/project_icon_six.png';
import projectIconSeven from '@/assets/images/batteryIndustry/project_icon_seven.png';
import projectIconEight from '@/assets/images/batteryIndustry/project_icon_eight.png';

import lineIconOne from '@/assets/images/batteryIndustry/line_icon_one.png';
import lineIconTwo from '@/assets/images/batteryIndustry/line_icon_two.png';
import lineIconThree from '@/assets/images/batteryIndustry/line_icon_three.png';
import lineIconFour from '@/assets/images/batteryIndustry/line_icon_four.png';
import lineIconFive from '@/assets/images/batteryIndustry/line_icon_five.png';
import lineIconSix from '@/assets/images/batteryIndustry/line_icon_six.png';
import lineIconSeven from '@/assets/images/batteryIndustry/line_icon_seven.png';
export default {
  name: 'webBatteryIndustry',
  data() {
    return {
      decorationIcon,
      cardOneIcon,
      cardTwoIcon,
      industryBackgroundImg,
      cardIndex: -1,
      firstOneImg,
      firstTwoImg,
      firstThreeImg,
      secondOneImg,
      secondTwoImg,
      lineArrowImg,
      batteryValues: [
        // 助力产业链协同
        {
          batteryImg: batteryImgOne,
          backgroundImg: backgroundImgOne,
          batteryActiveImg: batteryActiveImgOne,
          backgroundActiveImg: backgroundActiveImgOne,
          batteryTitle: '助力产业链协同',
          batteryTags: ['研发协同', '生产协同', '物流协同协同'],
          batteryActiveLabel: '通过数字化技术打造电池数字护照，实现研发协同、设计协同、生产协同、物流协同等，利用统一的数据格式和标准，打通企业间的数据壁垒，整个产业的运作更高效、更智能。',
        },
        // 提升供应链韧性
        {
          batteryImg: batteryImgTwo,
          backgroundImg: backgroundImgTwo,
          batteryActiveImg: batteryActiveImgTwo,
          backgroundActiveImg: backgroundActiveImgTwo,
          batteryTitle: '提升供应链韧性',
          batteryTags: ['递归网络', '增强供应链的透明度'],
          batteryActiveLabel: '电池数字护照构建一个递归网络，通过将供应链相关企业的数据拉通，增强供应链的透明度、适应能力和抗压性，从而保证供应链的韧性。'
        },
        // 保障产业链数据安全
        {
          batteryImg: batteryImgThree,
          backgroundImg: backgroundImgThree,
          batteryActiveImg: batteryActiveImgThree,
          backgroundActiveImg: backgroundActiveImgThree,
          batteryTitle: '保障产业链数据安全',
          batteryTags: ['研发协同', '生产协同', '物流协同协同'],
          batteryActiveLabel: '电池数字护照将数据披露权限掌握在企业手上，针对不同角色，开放不同数据内容。并且，电池护数字照体系设计利用数据加密、区块链等技术从根本上保证数据安全、可信交互。'
        }
      ],
      projectSolutions: [
        {
          projectTitle: '护照申请',
          projectLabel: '企业进行电池护照申请，通过电池护照技术，用户可以查询电池历史信息并进行评估，以确保电池的质量和性能。',
          projectIcon: projectIconOne
        },
        {
          projectTitle: '数据采集',
          projectLabel: '通过数据收集，开启产品五大生命周期阶段核算，最终编制一份产品碳足迹报告。',
          projectIcon: projectIconTwo
        },
        {
          projectTitle: '数据核验',
          projectLabel: '系统后台自动进行数据核验，确保数据的准确性、完整性和可靠性。',
          projectIcon: projectIconThree
        },
        {
          projectTitle: '数据标识',
          projectLabel: '通过赋提供“一物一码”服务，为每个产品配备唯一碳足迹二维码标识，实现在产业链中产品低碳属性的可查询、产品流动情况的可追踪。',
          projectIcon: projectIconFour
        },
        {
          projectTitle: '三方认证',
          projectLabel: '认证通过的产品碳足迹结果，通过公信认证机构，发放数据认证报告及认可轻碳标识。',
          projectIcon: projectIconFive
        },
        {
          projectTitle: '护照发行',
          projectLabel: '三方行业知名机构参与平台站台背书，为企业提高报告公信力，确保电池能够在欧洲市场上流通。',
          projectIcon: projectIconSix
        },
        {
          projectTitle: '护照溯源',
          projectLabel: '通过登录产品碳足迹追溯服务平台可生成的专属追溯二维码，通过微信扫一扫，即可打开小程序展示产品碳足迹追溯分析。',
          projectIcon: projectIconSeven
        },
        {
          projectTitle: '区块链存证',
          projectLabel: '通过区块链上链存证的碳足迹报告，皆可通过碳账本公示披露平台进行公示查询。',
          projectIcon: projectIconEight
        },
      ],
      // 时间轴
      timelines: [
        {
          time: '2023.08.17',
          label: '欧盟新电池法正式成立',
          lineIcon: lineIconOne,
          style: {
            block: {
              width: '1.51rem',
              height: '1.06rem',
              marginTop: '1.72rem',
            },
            img: {
              width: '0.1rem',
              height: '1.02rem',
              marginTop: '0.04rem',
            },
            text: {
              width: '1.33rem',
              height: '1.06rem'
            },
          }
        },
        {
          time: '2024.04.30',
          label: '出台电动汽车电池碳足迹计算规则草案',
          lineIcon: lineIconTwo,
          style: {
            block: {
              width: '1.58rem',
              height: '1.38rem',
              margin: '1.42rem 0 0 0.62rem'
            },
            img: {
              width: '0.1rem',
              height: '1.34rem',
              marginTop: '0.04rem',
            },
            text: {
              width: '1.4rem',
              height: '1.38rem'
            },
          },
        },
        {
          time: '2024.08.18',
          label: '电池性能和耐久性、电池健康及寿命预测、储能电池的安全性',
          lineIcon: lineIconThree,
          style: {
            block: {
              width: '1.53rem',
              height: '2.61rem',
              margin: '1.12rem 0 0 0.62rem'
            },
            img: {
              width: '0.1rem',
              height: '2.57rem',
              marginTop: '0.04rem',
            },
            text: {
              width: '1.35rem',
              height: '1.06rem'
            },
          },
        },
        {
          time: '2025.02.18',
          label: '电动汽车电池碳足迹声明、出台工业电池（无外部存储，2kwh以上）碳足迹计算规则',
          lineIcon: lineIconFour,
          style: {
            block: {
              width: '1.59rem',
              height: '2.66rem',
              margin: '0.82rem 0 0 0.62rem'
            },
            img: {
              width: '0.1rem',
              height: '2.57rem',
              marginTop: '0.04rem',
            },
            text: {
              width: '1.41rem',
              height: '2.66rem'
            }
          },
        },
        {
          time: '2025.12.31',
          label: '电池回收率要求：<br />铅酸电池：75%<br />锂基电池：65%<br />镍镉电池：80%<br />其他：50%',
          lineIcon: lineIconFive,
          style: {
            block: {
              width: '1.89rem',
              height: '2.61rem',
              margin: '0.62rem 0 0 0.56rem'
            },
            img: {
              width: '0.1rem',
              height: '2.57rem',
              marginTop: '0.04rem',
            },
            text: {
              width: '1.70rem',
              height: '2.02rem'
            }
          },
        },
        {
          time: '2026.02.18',
          label: '电池信息标签工业电池（无外部存储，2kwh以上）碳足迹声明',
          lineIcon: lineIconSix,
          style: {
            block: {
              width: '1.53rem',
              height: '2.61rem',
              margin: '0.32rem 0 0 0.54rem'
            },
            img: {
              width: '0.1rem',
              height: '2.57rem',
              marginTop: '0.04rem',
            },
            text: {
              width: '1.35rem',
              height: '2.02rem'
            }
          },
        },
        {
          time: '2027.02.18',
          label: '轻型交通工具电池、工业电池（2kwh以上）、电动汽车电池应有电池护照',
          lineIcon: lineIconSeven,
          style: {
            block: {
              width: '1.91rem',
              height: '2.78rem',
              margin: '0.16rem 0 0 0.62rem'
            },
            img: {
              width: '0.1rem',
              height: '2.57rem',
              marginTop: '0.04rem',
            }
          },
        }
      ]
    };
  },
};
</script>
<style lang="less" scoped>
@import url('./index.less');
</style>
