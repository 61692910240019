<template>
  <div class="workNews-page">
    <!-- 工作动态 -->
    <div class="workNews-header" :style="`background-image: url(${frameBackgroundImg})`">
      <div class="workNews-header_text">
        <p>工作动态</p>
        <p>Work dynamics</p>
      </div>
    </div>
    <!-- 列表 -->
    <div class="workNews-list">
      <div
        class="workNews-list_item"
        v-for="(item, index) in cardList"
        :key="index"
      >
        <img :src="item.img" />
        <div class="list_item-box">
          <div class="item-box_label">
            <label>{{ item.label }}</label>
          </div>
          <div class="item-box_expandLabel" v-if="item.expandLabel">
            <label>{{ item.expandLabel }}</label>
          </div>
          <div class="item-box_footer">
            <span>{{ item.time }}</span>
            <span>查看详情</span>
          </div>
        </div>
      </div>
    </div>
    <footer-nav />
  </div>
</template>
<script>
import cardOneImg from '@/assets/images/workNews/mobile/cardOneImg.png';
import cardTwoImg from '@/assets/images/workNews/mobile/cardTwoImg.png';
import cardThreeImg from '@/assets/images/workNews/mobile/cardThreeImg.png';
import cardFourImg from '@/assets/images/workNews/mobile/cardFourImg.png';
import cardFiveImg from '@/assets/images/workNews/mobile/cardFiveImg.png';
import frameBackgroundImg from '@/assets/images/workNews/mobile/frameBackgroundImg.png';
export default {
  name: "mobileWorkNews",
  data() {
    return {
      frameBackgroundImg,
      cardList: [
        {
          img: cardOneImg,
          label: "复创大事记 | 人民网：一块锂电池的逐“绿”之旅",
          expandLabel:
            "南京复创智能制造技术有限责任公司执行副总裁丁建军介绍：开发产品数字护照是基于两个背景。中国的电池产品若要出口至欧洲多家...",
          time: "2023.03.11-06.10",
        },
        {
          img: cardTwoImg,
          label: "南京复创正式加入全球电池联盟-（GBA)",
          expandLabel:
            "2023年12月，全球电池联盟（GBA）正式批准南京复创作为全球组织的新成员加入。",
          time: "2023.03.11-06.10",
        },
        {
          img: cardThreeImg,
          label:
            "2024 星火生态大会｜绿色低碳数字创新研讨会成功举办，南京复创受邀出席并做主题汇报",
          expandLabel: "",
          time: "2023.03.11-06.10",
        },
        {
          img: cardFourImg,
          label: "南京复创入选Pegasus投融研究所《产品数字护照行业研究报告》",
          expandLabel:
            "南京复创作为行业领先的数字化技术解决方案提供商，以电池行业为试点，建设DPP解决...",
          time: "2023.03.11-06.10",
        },
        {
          img: cardFiveImg,
          label:
            "活动回顾丨南京复创参与中国电池工业协会电池护照工作推进会并做主题汇报",
          time: "2023.03.11-06.10",
        },
      ],
    };
  },
};
</script>
<style lang="less" scoped>
@import url("./index.less");
</style>