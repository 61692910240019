<template>
    <div class="home-page">
        <div class="home-header" :style="`background-image: url(${headerImg})`">
            <div class="home-header_text">
                <p>欢迎来到</p>
                <p>中国产品数字护照网</p>
                <p>专注中国产品数字护照的专业门户，中国产品数字护照体系服务的客户端统一入口。</p>
            </div>
        </div>
        <!-- 产品数字护照 -->
        <div class="home-passport">
            <div class="home-title">
                <img :src="decorationIcon" />
                <label>产品数字护照</label>
            </div>
            <div class="home-list">
                <div class="home-list_title">什么是产品护照?</div>
                <div class="home-list_item" v-for="(item, index) in passportList" :key="index">
                    <div class="list_item-icon">
                        <img :src="checkIcon" />
                    </div>
                    <div class="list_item-text">{{ item.label }}</div>
                </div>
            </div>
        </div>
        <!-- 中国产品数字护照网 -->
        <div class="home-network">
            <div class="home-title">
                <img :src="decorationIcon" />
                <label>中国产品数字护照网</label>
            </div>
            <div class="home-tabs">
                <div class="home-tabs_item" v-for="(item, index) in netWorkList" :key="index">
                    <div class="tabs_item-title">{{ item.title }}</div>
                </div>
            </div>
        </div>
        <!-- 最新工作动态 -->
        <div class="home-dynamics">
            <div class="home-title">
                <img :src="decorationIcon" />
                <label>最新工作动态</label>
            </div>
        </div>
        <footer-nav />
    </div>
</template>
<script>
import decorationIcon from '@/assets/images/home/mobile/decorationIcon.png';
import cardIcon from '@/assets/images/home/mobile/cardIcon.png';
import dotIcon from '@/assets/images/home/mobile/dotIcon.png';
import cardBottomIcon from '@/assets/images/home/mobile/cardBottomIcon.png';
import cardTopIcon from '@/assets/images/home/mobile/cardTopIcon.png';
import checkIcon from '@/assets/images/home/mobile/checkIcon.png';
import checkYIcon from '@/assets/images/home/mobile/checkYIcon.png';
import headerImg from '@/assets/images/home/mobile/headerImg.png';
export default {
    name: 'mobileHome',
    data() {
        return {
            decorationIcon,
            cardIcon,
            dotIcon,
            cardBottomIcon,
            cardTopIcon,
            checkIcon,
            checkYIcon,
            headerImg,
            passportList: [
                {
                   label: '产品数字护照是人类历史上首次构建的数据共享机制。' 
                },
                {
                    label: '具有全球性、跨行业、多主体,并且涵盖ESG数据等属性。'
                },
                {
                    label: '为碳边境调节税机制调整奠定基础，为再全球化区域经济、贸易的纵深发展提供支撑。'
                },
                {
                    label: '是数字化转型和绿色可持续发展转型的标志。'
                },
                {
                    label: '是碳管理及企业“数绿双转”的重要技术手段。'
                }
            ],
            netWorkList: [
                {
                    title: '宗旨与愿景',
                    list: [
                        { label: '为更好的资源循环' },
                        { label: '为更低的能源碳耗' },
                        { label: '为促进循环经济' },
                        { label: '实现绿色可持续发展' }
                    ]
                },
                {
                    title: '目标与任务',
                    list: [
                        { label: '以行业项目为切入点' },
                        { label: '定义中国产品数字护照体系解决方案' },
                        { label: '塑造中国产品数字护照的未来' }
                    ]
                },
                {
                    title: '工作与项目',
                    list: [
                        { label: 'DPP相关动态与政策标准研究' },
                        { label: '进行产品数字护照的行业试点与部署' },
                        { label: '产品数字护照企业联合试点验证服务'}
                    ]
                }
            ],
            dyamicsList: [
                {
                    title: '人民网 : 一块锂电池的逐“绿”之旅',
                    time: '2024年09月04日',
                },
                {
                    title: '南京复创入选Pegasus投融研究所《产品数字护照行业研究报告》',
                    time: '2024年06月28日',
                },
                {
                     title: '南京复创参与中国电池工业协会电池护照工作推进会并做主题汇报',
                     time: '2024年04月20日'
                }
            ]
        }
    },
    methods: {
    }
}
</script>
<style lang="less" scoped>
@import url('./index.less');
</style>