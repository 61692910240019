<template>
    <div class="page flex-col">
      <div class="report-header flex-col">
        <span class="report-header_one">权威报告</span>
        <span class="report-header_two">Authoritative&nbsp;reports</span>
      </div>
      <div class="report-middle flex-col">
        <div class="anrchor_class" id="report"></div>
        <div class="report-middle_box1 flex-row">
          <div class="middle_box1-image flex-row justify-between">
            <img class="box1-image_img" referrerpolicy="no-referrer" :src="decorationIcon" />
            <span class="box1-image_label">权威报告</span>
          </div>
        </div>
        <div class="report-middle_box2 flex-row justify-between">
          <div class="middle_box2-block1 flex-col justify-between">
            <div class="box2-block1_group1 flex-col">
              <span class="block1_group1_text1">WE&nbsp;ARE&nbsp;PROFESSIONAL</span>
              <span class="block1_group1_text2">产品电池护照(DPP)<br />技术发展报告</span>
              <span
                class="block1_group1_text3">电池护照是电池的电子记录，包含了电池从采矿到最终回收全生命周期中的所有信息。其目的是为了提升电池产业的透明度和认识，促进向循环经济的转变，并营造公平竞争的环境。<br /></span>
            </div>
            <div class="box2-block1_group2 flex-row justify-between"
              @click="downloadFile(reportUrl, '产品数字护照(DPP)技术发展报告')">
              <span class="block1_group2-text">点击此处下载</span>
              <div class="block1_group2-style flex-col">
                <img :src="arrowdownIcon" />
              </div>
            </div>
          </div>
        </div>
        <div class="report-middle_image flex-col">
          <img class="middle_image-img" referrerpolicy="no-referrer" :src="reportBackgroundTwoImg" />
        </div>
      </div>
      <div class="report-bottom flex-col">
        <div class="anrchor_class" id="digitalize"></div>
        <div class="report-bottom_image flex-row justify-between">
          <img class="bottom_image-img" referrerpolicy="no-referrer" :src="decorationIcon" />
          <span class="bottom_image-label">中国电池数字护照体系白皮书</span>
        </div>
        <div class="report-bottom_box flex-row justify-between">
          <div class="bottom_box-group flex-col justify-between">
            <div class="box-group_block flex-col">
              <span class="group_block-text1">WE&nbsp;ARE&nbsp;PROFESSIONAL</span>
              <span class="group_block-text2">中国电池数字护照体系白皮书声明</span>
              <span
                class="group_block-text3">本报告所载的材料和信息，包括单不限于文本、图片、数据、观点、建议不构成法律建议。本报告所有材料和内容的知识产权归中国电池工业协会所有（注明是引自其他文献内容除外），并受法律保护。如需转载，需联系本协会并获得授权许可。</span>
            </div>
            <div class="box-group_block1 flex-row justify-between" @click="downloadFile(whitePaperUrl, '中国电池数字护照体系白皮书')">
              <span class="group_block1-text">点击此处下载</span>
              <div class="group_block1-style flex-col">
                <img :src="arrowdownIcon" />
              </div>
            </div>
          </div>
          <img class="bottom_box-img" referrerpolicy="no-referrer" :src="passportBackgroundImg" />
        </div>
      </div>
    </div>
  </template>
  <script>
  import arrowdownIcon from '@/assets/images/technicalReport/arrow_down.png';
  import decorationIcon from '@/assets/images/home/decoration.png';
  import reportBackgroundOneImg from '@/assets/images/technicalReport/report_background_one.png';
  import reportBackgroundTwoImg from '@/assets/images/technicalReport/report_background_two.png';
  import passportBackgroundImg from '@/assets/images/technicalReport/passport_background.png';
  export default {
    name: 'webTechnicalReport',
    data() {
      return {
        arrowdownIcon,
        decorationIcon,
        reportBackgroundOneImg,
        reportBackgroundTwoImg,
        passportBackgroundImg,
        reportUrl: `${process.env.BASE_URL}pdf/产品数字护照(DPP)技术发展报告.pdf`,
        whitePaperUrl: `${process.env.BASE_URL}pdf/中国电池数字护照体系白皮书.pdf`
      };
    },
    methods: {
      // 处理二进制流
      downloadFile(url, fileName) {
        let link = document.createElement('a');
        link.href = url;
        link.style.display = 'none';
        link.download = `${fileName}.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },
  };
  </script>
  <style lang="less" scoped>
  @import url('./index.less');
  </style>
  