<template>
  <div class="aboutdpp-page">
    <div class="aboutdpp-header" :style="`background-image: url(${group3Img})`">
       <div class="aboutdpp-header_text">
          <p>关于DPP</p>
          <p>了解产品数字护照</p>
       </div>
    </div>
    <!-- 什么是产品数字护照? -->
    <div class="aboutdpp-what">
      <div class="aboutdpp-title">
         <img :src="decorationIcon" />
         <label>什么是产品数字护照?</label>
      </div>
      <div class="aboutdpp-content">
         <div class="aboutdpp-content_title">
            <label>DPP概念提出: 促进数绿双转的重要抓手</label>
         </div>
         <div class="aboutdpp-content_text">
            <span>
              目前，促进碳达峰碳中和已成为全球共识，数字技术赋能成为产业绿色低碳发展的重要路径。为促进数字化绿色化协同发展，2022年3月欧盟在《可持续产品生态设计法规》提案中明确提出了“产品数字护照”(Digital Product Passport,DPP)概念和要求，主要采集核验产品从设计、制造、物流、使用、回收所关联的各类绿色可持续信息。
            </span>
         </div>
         <div class="aboutdpp-content_img">
            <img :src="group1Img" />
         </div>
         <div class="aboutdpp-content_title">
            <label>欧盟《可持续产品生态设计法规》DPP性质概述</label>
         </div>
         <div class="aboutdpp-content_text">
            <span>
              产品数字护照的本质是一组特定于产品的、可通过数据载体进行电子访问的数据集，通过数据载体链接唯一标识符，在跨国贸易和流通中证明产品的产地、身份及可持续水平。
            </span>
         </div>
         <div class="aboutdpp-content_img">
            <img :src="group2Img" />
         </div>
      </div>
    </div>
    <!-- 产品数字护照的实施流程 -->
    <div class="aboutdpp-action" :style="`background-image: url(${frameImg})`">
      <div class="aboutdpp-title">
          <img :src="decorationIcon" />
          <label>产品数字护照的实施流程</label>
      </div>
      <div class="aboutdpp-content"></div>
    </div>
    <!-- 产品数字护照的意义有哪些 -->
    <div class="aboutdpp-meaning">
      <div class="aboutdpp-title">
          <label>产品数字护照的意义有哪些</label>
          <p>推动数字化转型, 促进可持续循环经济</p>
      </div>
      <div class="aboutdpp-list">
          <div class="aboutdpp-list_item" v-for="(item, index) in collapseList" :key="index">
              <div class="list_item-header" @click="collapse(index, item, 'collapseList')">
                 <label>{{ index + 1 }}、</label>
                 <span>{{ item.title }}</span>
                 <img :class="['arrow-icon', item.collapsed ? 'img-collapseUp' : 'img-collapseDown']" :src="arrowIcon" />
              </div>
              <div class="list_item-content" v-if="item.collapsed">
                  <p v-for="(subItem, subIndex) in item.list" :key="subIndex">
                     <label>{{ subItem }}</label>
                  </p>
              </div>
          </div>
      </div>
    </div>
    <footer-nav />
  </div>
</template>
<script>
import arrowIcon from '@/assets/images/aboutdpp/mobile/arrowIcon.png';
import decorationIcon from '@/assets/images/aboutdpp/mobile/decorationIcon.png';
import frameImg from '@/assets/images/aboutdpp/mobile/frameImg.png';
import group1Img from '@/assets/images/aboutdpp/mobile/group1Img.png';
import group2Img from '@/assets/images/aboutdpp/mobile/group2Img.png';
import group3Img from '@/assets/images/aboutdpp/mobile/group3Img.png';
export default {
  name: "mobileAboutdpp",
  data() {
    return {
      arrowIcon,
      decorationIcon,
      frameImg,
      group1Img,
      group2Img,
      group3Img,
      collapseList: [
         {
            title: '市场需求',
            collapsed: true,
            list: ['DPP响应碳达峰碳中和需求', '为数字化绿色化协同发展打造新路径']
         },
         {
            title: '行业趋势',
            collapsed: true,
            list: ['DPP加速产业链数字化转型', 'ESG数据深度整合成为趋势']
         },
         {
            title: '为全链路数字化运营赋能',
            collapsed: true,
            list: ['DPP契合新兴信息技术演进', '为数字基础设施规模化应用构建新场景']
         }
      ]
    };
  },
  methods: {
    collapse(index, item, key) {
      let handleItem = null;
      const funcItem = (collapsed) => {
        return {
          ...item,
          collapsed
        }
      };
      if(item.collapsed) {
        handleItem = funcItem(false);
      } else {
        handleItem = funcItem(true);
      }
      this[key] = this[key].map((element, eleIndex) => eleIndex == index ? handleItem : element)
    }
  }
};
</script>
<style lang="less" scoped>
@import url("./index.less");
</style>