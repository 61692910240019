<template>
    <div class="page flex-col">
      <div class="box_industry flex-col">
        <div class="text-wrapper_1 flex-col">
          <span class="text_1">行业资讯</span>
          <span class="text_2">Industry&nbsp;information</span>
        </div>
        <!-- 新闻列表 -->
       <div v-for="(item, index) in newsList" :key="index"> 
        <div class="group_news flex-row" v-if="item.cardUrl">
            <img class="image_card" referrerpolicy="no-referrer" :src="item.cardImg" />
            <div class="card_body flex-col">
              <span class="body_card_title">{{ item.cardTitle }}</span>
              <div class="body_card_date">
                <div class="card_date_body flex-row justify-between">
                  <div class="date_body_wrapper flex-col">
                    <img class="date_body_image" referrerpolicy="no-referrer" :src="item.cardIcon" />
                  </div>
                  <span class="date_body_text">{{ item.cardDate }}</span>
                </div>
              </div>
              <span class="body_card_content" v-if="item.cardContent">{{ item.cardContent }}</span>
              <div class="body_card_btns flex-col">
                <span type="primary" class="card_btns_text" @click="convertUrl(item.cardUrl)">查看详情</span>
              </div>
            </div>
        </div>
      </div> 
        <footer-nav />
      </div>
    </div>
  </template>
  <script>
  import timeIcon from '@/assets/images/policyComment/time-icon.png';
  import cardIconOne from '@/assets/images/industryInformation/card_icon_one.png';
  import cardIconTwo from '@/assets/images/industryInformation/card_icon_two.png';
  import cardIconThree from '@/assets/images/industryInformation/card_icon_three.png';
  import cardIconFour from '@/assets/images/industryInformation/card_icon_four.png';
  import cardIconFive from '@/assets/images/industryInformation/card_icon_five.png';
  import cardIconSix from '@/assets/images/industryInformation/card_icon_six.png';
  import cardIconSeven from '@/assets/images/industryInformation/card_icon_seven.png';
  export default {
    name: 'webIndustryInformation',
    data() {
      return {
        newsList: [
          { 
              cardImg: cardIconOne,
              cardTitle: '中国信通院联合发布《产品数字护照（DPP）技术发展报告（2023年）》',
              cardIcon: timeIcon,
              cardDate: '2023年12月29日 10:01:00',
              cardBtn: '查看详情',
              cardUrl: 'https://mp.weixin.qq.com/s/icrGYceHOraPOHBL8WK3vg',
          },
          { 
              cardImg: cardIconTwo,
              cardTitle: '国内首个电池数字护照工作组正式开始组建',
              cardIcon: timeIcon,
              cardDate: '2023年12月27日 17:19:00',
              cardContent: '自2020年欧盟委员公布了《新电池法》草案以来，中国电池工业协会一直关注法案进度，在今年8月法案生效后，10月协会在北京组织召开欧盟《电池与废电池法规》......',
              cardBtn: '查看详情',
              cardUrl: 'https://mp.weixin.qq.com/s/4TQotVxUMbzaD2g4uBF4xA',
          },
          { 
              cardImg: cardIconThree,
              cardTitle: '易碳洞察 | 欧盟法案助推，GBA电池护照开启全球电池价值链数字化新篇章',
              cardIcon: timeIcon,
              cardDate: '2023年2月4日 10:00:13',
              cardContent: '《欧盟电池及废旧电池法规》规定，从2027年开始，欧洲市场将对进口动力电池实施更加严格的监管，要求电池出口商携带符合规定的“电池护照”。',
              cardBtn: '查看详情',
              cardUrl: '',  
          },
          { 
              cardImg: cardIconFour,
              cardTitle: '海关科普：带你了解欧盟《新电池法规》',
              cardIcon: timeIcon,
              cardDate: '2023年08月28日 17:44:00',
              cardContent: '欧盟《新电池法规》的管理对象包含了5类电池，即便携式电池、工业电池、轻型运输工具（LMT）电池、汽车电池以及电动汽车电池。7月28日，欧盟官方公报正式颁......',
              cardBtn: '查看详情',
              cardUrl: 'https://mp.weixin.qq.com/s/iUeycPoVXlEOolEJZ_jeUg',   
          },
          { 
              cardImg: cardIconFive,
              cardTitle: '国际动态 | 欧盟“数字产品护照DPP”立法持续推进，纺织服装企业提早应对新挑战',
              cardIcon: timeIcon,
              cardDate: '2023年04月10日 15:46:00',
              cardBtn: '查看详情',
              cardUrl: 'https://mp.weixin.qq.com/s/ox90NRVMYOrBxS5AzynZjA',  
          },
          { 
              cardImg: cardIconSix,
              cardTitle: '宁德时代曾毓群两会提案：首次提出中国电池护照观点！',
              cardIcon: timeIcon,
              cardDate: '2023年03月03日 11:34:00',
              cardContent: '3月2日，两会前夕，全国政协委员、宁德时代新能源科技股份有限公司董事长曾毓群提交《关于加快推进电池储能高质量发展的提案》、《关于开展我国动力电池护照及......',
              cardBtn: '查看详情',
              cardUrl: 'https://mp.weixin.qq.com/s/gdhY7oZvoAurQXhNOPNlJA',  
          },
          { 
              cardImg: cardIconSeven,
              cardTitle: '“电池护照”是不是宁德时代们的利好？',
              cardIcon: timeIcon,
              cardDate: '2023年2月4日 10:00:13',
              cardContent: '1月18日，在瑞士举行的达沃斯世界经济论坛上，全球电池联盟（GBA）首次发布了电池护照概念验证成果。',
              cardBtn: '查看详情',
              cardUrl: '', 
          }
        ]
      };
    },
    methods: {
      convertUrl(url) {
        window.open(url, '_blank')
      }
    },
  };
  </script>
  <style lang="less" scoped>
  @import url('./index.less');
  </style>
  