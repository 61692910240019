<template>
  <div class="technicalReport-page">
    <!-- 权威报告 -->
    <div
      class="technicalReport-header"
      :style="`background-image: url(${backgroundImg})`"
    >
      <div class="technicalReport-header_text">
        <p>权威报告</p>
        <p>Authoritative reports</p>
      </div>
    </div>
    <!-- 卡片内容 -->
    <div class="technicalReport-content" :style="`background-image: url(${decorationBackgroundImg})`">
      <!-- 卡片1 -->
      <div class="technicalReport-content_card1">
        <div class="technicalReport-title">
          <img :src="decorationIcon" />
          <label>权威报告</label>
        </div>
        <div class="content_card1-block">
          <p>产品电池护照(DPP)技术发展报告</p>
          <div class="card1-block_box">
            <div class="block_box-left">
              <span>
                电池护照是电池的电子记录，包含了电池从采矿到最终回收全生命周期中的所有信息。其目的是为了提升电池产业的透明度和认识，促进向循环经济的转变，并营造公平竞争的环境。
              </span>
            </div>
            <img :src="frameCardOneImg" />
          </div>
          <div class="card1-block_bottom">
            <span>点击此处下载</span>
            <img :src="downloadIcon" />
          </div>
        </div>
      </div>
      <!-- 卡片2 -->
      <div class="technicalReport-content_card2">
        <div class="technicalReport-title">
          <img :src="decorationIcon" />
          <label>中国电池数字护照体系白皮书</label>
        </div>
        <div class="content_card2-block">
          <p>中国电池数字护照体系白皮书声明</p>
          <div class="card2-block_box">
            <div class="block_box-left">
              <span>
                本报告所载的材料和信息，包括单不限于文本、图片、数据、观点、建议不构成法律建议。本报告所有材料和内容的知识产权归中国电池工业协会所有（注明是引自其他文献内容除外），并受法律保护。如需转载，需联系本协会并获得授权许可。
              </span>
            </div>
            <img :src="frameCardTwoImg" />
          </div>
          <div class="card2-block_bottom">
            <span>点击此处下载</span>
            <img :src="downloadIcon" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import decorationBackgroundImg from "@/assets/images/technicalReport/mobile/decorationBackgroundImg.png";
import backgroundImg from "@/assets/images/technicalReport/mobile/backgroundImg.png";
import decorationIcon from "@/assets/images/technicalReport/mobile/decorationIcon.png";
import frameCardOneImg from "@/assets/images/technicalReport/mobile/frameCardOneImg.png";
import frameCardTwoImg from "@/assets/images/technicalReport/mobile/frameCardTwoImg.png";
import downloadIcon from "@/assets/images/technicalReport/mobile/downloadIcon.png";
export default {
  name: "mobileTechnicalReport",
  data() {
    return {
      decorationBackgroundImg,
      backgroundImg,
      decorationIcon,
      frameCardOneImg,
      frameCardTwoImg,
      downloadIcon,
    };
  },
};
</script>
<style lang="less" scoped>
@import url("./index.less");
</style>