<template>
  <div class="industryInformation-page">
    <!-- 行业资讯 -->
    <div class="industryInformation-header" :style="`background-image: url(${groupBackgroundImg})`">
      <div class="industryInformation-header_text">
        <p>行业资讯</p>
        <p>Industry information</p>
      </div>
    </div>
    <!-- 列表 -->
    <div class="industryInformation-list">
      <div
        class="industryInformation-list_item"
        v-for="(item, index) in cardList"
        :key="index"
      >
        <img :src="item.img" />
        <div class="list_item-box">
          <div class="item-box_label">
            <label>{{ item.label }}</label>
          </div>
          <div class="item-box_expandLabel" v-if="item.expandLabel">
            <label>{{ item.expandLabel }}</label>
          </div>
          <div class="item-box_footer">
            <span>{{ item.time }}</span>
            <span>查看详情</span>
          </div>
        </div>
      </div>
    </div>
    <footer-nav />
  </div>
</template>
<script>
import imageCardOne from '@/assets/images/industryInformation/mobile/imageCardOne.png';
import imageCardTwo from '@/assets/images/industryInformation/mobile/imageCardTwo.png';
import imageCardThree from '@/assets/images/industryInformation/mobile/imageCardThree.png';
import imageCardFour from '@/assets/images/industryInformation/mobile/imageCardFour.png';
import imageCardFive from '@/assets/images/industryInformation/mobile/imageCardFive.png';
import groupBackgroundImg from '@/assets/images/industryInformation/mobile/groupBackgroundImg.png';
export default {
  name: "mobileIndustryInformation",
  data() {
    return {
      groupBackgroundImg,
      cardList: [
        {
          img: imageCardOne,
          label:
            "中国信通院联合发布《产品数字护照（DPP）技术发展报告（2023年）》",
          time: "2023.03.11-06.10",
        },
        {
          img: imageCardTwo,
          label: "国内首个电池数字护照工作组正式开始组建",
          expandLabel:
            "2023年12月，全球电池联盟（GBA）正式批准南京复创作为全球组织的新成员加入。",
          time: "2023.03.11-06.10",
        },
        {
          img: imageCardThree,
          label: "海关科普：带你了解欧盟《新电池法规》",
          expandLabel:
            "欧盟《新电池法规》的管理对象包含了5类电池，即便携式电池、工业电池、轻型运输工...",
          time: "2023.03.11-06.10",
        },
        {
          img: imageCardFour,
          label:
            "国际动态 | 欧盟“数字产品护照DPP” 立法持续推进，纺织服装企业提早应对新挑战",
          time: "2023.03.11-06.10",
        },
        {
          img: imageCardFive,
          label: "宁德时代曾毓群两会提案：首次提出中国电池护照观点！",
          expandLabel:
            "3月2日，两会前夕，全国政协委员、宁德时代新能源科技股份有限公司董事长曾毓群提交...",
          time: "2023.03.11-06.10",
        },
      ],
    };
  },
};
</script>
<style lang="less" scoped>
@import url("./index.less");
</style>