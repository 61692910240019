<template>
    <div class="policyComment-page">
        <!-- 政策解读 -->
        <div class="policyComment-header" :style="`background-image: url(${frameBackgroundImg})`">
            <div class="policyComment-header_text">
                <p>政策解读</p>
                <p>Policy interpretation</p>
            </div>
        </div>
        <!-- 列表 -->
        <div class="policyComment-list">
            <div class="policyComment-list_item" v-for="(item, index) in cardList" :key="index">
                    <img :src="item.img" />
                    <div class="list_item-box">
                        <div class="item-box_label"><label>{{ item.label }}</label></div>
                        <div class="item-box_expandLabel" v-if="item.expandLabel">
                            <label>{{ item.expandLabel }}</label>
                        </div>
                        <div class="item-box_footer">
                            <span>{{item.time }}</span>
                            <span>查看详情</span>
                        </div>
                    </div>
            </div>
        </div>
        <footer-nav />
    </div>
</template>
<script>
import frameCardImg from '@/assets/images/policyComment/mobile/frameCardImg.png';
import frameBackgroundImg from '@/assets/images/policyComment/mobile/frameBackgroundImg.png';
export default {
    name: 'mobilePolicyComment',
    data() {
        return {
            frameCardImg,
            frameBackgroundImg,
            cardList: [
                {
                    img: frameCardImg,
                    label: '政策简报｜促进汽车循环的数字车辆护照',
                    expandLabel: '欧盟委员会于2023年7月发布循环车辆条例草案，旨在推动汽车行业从设计到报废处理全....',
                    time: '2023.03.11-06.10'
                },
                {
                    img: frameCardImg,
                    label: '关于欧盟《新电池法案》解读之数字电池护照',
                    expandLabel: '自2027年2月18日起，每一块LMT电池、每一块容量大于2千瓦时的工业电池以及每一块...',
                    time: '2023.03.11-06.10'
                },
                {
                    img: frameCardImg,
                    label: '欧盟电池法规详解《电池回收》',
                    expandLabel: '欧洲电池法规于2023年8月17日正式生效，这一法规的核心目标是推动电池产业向更加可...',
                    time: '2023.03.11-06.10'
                },
                {
                    img: frameCardImg,
                    label: '关于欧盟《新电池法案》解读之碳足迹',
                    expandLabel: '南京复创作为行业领先的数字化技术解决方案提供商，以电池行业为试点，建设DPP解决。',
                    time: '2023.03.11-06.10'
                }
            ]
        }
    }
}
</script>
<style lang="less" scoped>
@import url('./index.less');
</style>